export const retryablePromise = (
  promiseFn,
  retryCount = 2,
  retryInterval = 1000,
  retryIntervalIncrease = 750
) => {
  return new Promise((resolve, reject) => {
    let retry = 0;
    let retryWaitTime = retryInterval;
    const retryFn = async () => {
      console.trace('trying', retry);
      await promiseFn()
        .then((value) => {
          const error = value?.error;

          if (error) throw new Error(error);

          resolve(value);
        })
        .catch((err) => {
          console.log('retrying', retry, err);
          if (retry < retryCount) {
            retry += 1;
            retryWaitTime += retryIntervalIncrease;
            setTimeout(retryFn, retryWaitTime);
          } else {
            reject(err);
          }
        });
    };
    retryFn();
  });
};
