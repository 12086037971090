import axios from 'axios';
import config from '../utility/config';
import { getDataFromAxiosRes } from './helpers';
import authorizedRequest from './helpers/authorizedRequest';

const {
  apiPaymentBasePath,
  apiNewPaymentBasePath,
  backendPaymentAuthKey
} = config;

export const signupService = async (
  courseCode,
  courseType,
  agreeTermsAndCondition,
  timezone,
  basicInfo,
  signupId = null
) => {
  try {
    const path = apiPaymentBasePath + '/api/v1/sign-up/';
    const options = {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'auth-token': backendPaymentAuthKey
      },
      body: JSON.stringify({
        courseCode,
        courseType,
        agreeTermsAndCondition,
        timezone,
        basicInfo: basicInfo.map((basicUserInfo) => {
          return { studentInfo: basicUserInfo };
        }),
        signupId
      })
    };
    const res = await fetch(path, options);
    const data = await res.json();

    if (data.error) {
      throw new Error(data.info || data.error);
    }

    return data;
  } catch (e) {
    return { error: e.message };
  }
};

// export const sendLeadEmailService = async ({
export const initiateCheckoutWithEmail = async ({
  courseCode,
  courseType,
  timezone,
  basicInfo,
  trackingData,
  captchaToken,
  isDirectSignUpEmail
}) => {
  try {
    const path = apiPaymentBasePath + '/api/v1/email-sign-up/';

    const realBasicInfo = basicInfo.map((info) => {
      const studentInfo = info?.studentInfo;
      if (!studentInfo?.phone && studentInfo?.phone?.length === 0) {
        delete studentInfo.phone;
      }
      return { studentInfo };
    });
    const options = {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'auth-token': backendPaymentAuthKey
      },

      body: JSON.stringify({
        courseCode,
        courseType,
        timezone,
        basicInfo: realBasicInfo,
        trackingData,
        captchaToken,
        isDirectSignUpEmail
      })
    };
    const res = await fetch(path, options);
    const data = await res.json();

    if (data.error) {
      const errorObj = new Error(data.info || data.error);
      errorObj.code = data?.code;
      throw errorObj;
    }

    return { data };
  } catch (e) {
    return { error: { code: e.code, message: e.message } };
  }
};

export const applyDiscountCodeService = async (couponCode) => {
  const path = apiPaymentBasePath + '/api/v1/sign-up-apply-discount/';
  const body = JSON.stringify({ couponCode });

  const options = {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json'
    },
    body
  };

  const response = await authorizedRequest(path, options);
  return response;
};

/**
 * Payment service for enrollment
 * @param {object} courseCode The course code
 * - currency: string ("USD")
 * - paymentType: "full" | "deposit"
 * - discount? bool
 * - credit? bool
 * - isCommunity? bool
 *
 * @return {boolean} True if course code is for teen course, and False otherwise.
 */
export const signUpPaymentService = async ({ params, endpoint = '' }) => {
  params.paymentType = params.paymentType || 'full';
  const path = apiPaymentBasePath + `/api/v1/sign-up-payment/${endpoint}`;
  const options = {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(params)
  };
  const response = await authorizedRequest(path, options);
  return response;
};

export const getRedirectLinkOnPaymentSuccess = async (params) => {
  const { bookingId, isCommunity } = params;
  const path =
    apiPaymentBasePath +
    `/api/v1/redirect-link?bookingId=${bookingId}&courseType=general${
      isCommunity ? '&isCommunity=1' : ''
    }`;
  const options = { method: 'GET' };

  if (params.accessToken) {
    options.headers = { Authorization: `Bearer ${params.accessToken}` };
  }

  const res = await authorizedRequest(path, options);

  return res;
};

export const getPaymentMethodsByCountry = async (countryId) => {
  try {
    const path = `${apiNewPaymentBasePath}/api/v1/payment-methods?countryId=${countryId}`;

    const options = {
      method: 'GET',
      headers: {
        Authorization: 'Bearer backendmiddlewarenasaccademy'
      }
    };
    const res = await axios.get(path, options);
    const data = await getDataFromAxiosRes(res);

    if (data.error) {
      throw new Error(data.info || data.error);
    }

    return { data };
  } catch (e) {
    return { error: e.message };
  }
};

/**
 * This function gets the payment method for a given country and checkout type.
 *
 * @param {string} country The country name.
 * @param {string} checkoutType The checkout type. Can be one of "recurring" or "onetime".
 */
export const getPaymentMethods = async ({
  country,
  checkoutType,
  communityCode
}) => {
  try {
    const path =
      apiNewPaymentBasePath +
      `/api/v1/local-payment-methods?country=${country}&checkoutType=${checkoutType}&communityCode=${communityCode}`;
    const options = {
      method: 'GET',
      headers: {
        Authorization: 'Bearer backendmiddlewarenasaccademy'
      }
    };
    const res = await axios.get(path, options);
    const data = await getDataFromAxiosRes(res);

    if (data.error) {
      throw new Error(data.info || data.error);
    }

    return { data };
  } catch (e) {
    return { error: e.message };
  }
};

/**
 * @param {*} params.paymentMethod
 * @param {*} params.currency
 * @param {*} params.paymentType
 */
export const signUpRapydCheckout = async (params) => {
  const path = apiPaymentBasePath + '/api/v1/sign-up-checkout/';

  const options = {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(params)
  };

  const response = await authorizedRequest(path, options);
  return response;
};
