import { PropTypes } from 'prop-types';
import React from 'react';

import Button from '../../Button';
import AlertIcon from '../../Icons/AlertIcon';
import Prompt from '../../Prompt';
import style from './CheckoutErrorPrompt.module.scss';

const CheckoutErrorPrompt = ({ message, onClose }) => {
  const className = 'c-CheckoutErrorPrompt';
  const baseClassName = `${className} ${style[className]}`;

  return (
    <div className={baseClassName}>
      <Prompt show={true} onBackdropClick={onClose}>
        <>
          <div className={style[`${className}__empty`]}>
            <div>
              <AlertIcon />
            </div>
          </div>
          <div className={style[`${className}__title`]}>
            <h4 className={style[`${className}__h4`]}>
              Oops something went wrong
            </h4>
            <p className={style[`${className}__p`]}>{message}</p>
            <Button displayType="form-primary" onClick={onClose}>
              Close
            </Button>
          </div>
        </>
      </Prompt>
    </div>
  );
};

CheckoutErrorPrompt.propTypes = {
  message: PropTypes.string.isRequired,
  onClose: PropTypes.func.isRequired
};

export default CheckoutErrorPrompt;
