/* eslint-disable jsx-a11y/click-events-have-key-events */
import classNames from 'classnames';
import { PropTypes } from 'prop-types';
import React from 'react';

import Backdrop from '../Backdrop';
import style from './Prompt.module.scss';

const Prompt = ({
  show,
  showCloseIcon,
  onClose,
  onBackdropClick,
  children
}) => {
  const className = 'c-Prompt';
  const baseClassName = classNames(className, style[className]);
  const containerClassName = `${className}__container`;
  const containerClassNames = classNames(
    containerClassName,
    style[containerClassName]
  );

  const handleClose = () => {
    if (onClose) {
      onClose();
    } else {
      onBackdropClick();
    }
  };

  if (!show) return null;

  return (
    <div className={baseClassName}>
      <Backdrop show={true} clicked={onBackdropClick} />
      <div className={containerClassNames}>
        {showCloseIcon && (
          <div
            role="button"
            tabIndex={0}
            className={`${className}__close`}
            onClick={handleClose}></div>
        )}
        {children}
      </div>
    </div>
  );
};

Prompt.defaultProps = {
  showCloseIcon: false
};

Prompt.propTypes = {
  children: PropTypes.element.isRequired,
  onBackdropClick: PropTypes.func.isRequired
};

export default Prompt;
