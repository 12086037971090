import config from '@/utility/config';

const { apiPaymentBasePath, backendPaymentAuthKey } = config;

export const communitiesSignUpService = async (payload) => {
  try {
    const path = apiPaymentBasePath + '/api/v1/community-signup';
    const options = {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'auth-token': backendPaymentAuthKey
      },
      body: JSON.stringify(payload)
    };
    const res = await fetch(path, options);
    const data = await res.json();

    if (data?.error) {
      return { error: data };
    }
    return data;
  } catch (e) {
    return { error: e.message };
  }
};
