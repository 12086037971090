import { useEffect, useRef, useState } from 'react';

import CheckoutErrorPrompt from '../CheckoutErrorPrompt';
import Link from 'next/link';
import LoadingCircle from '../../../../components/common/LoadingCircle/LoadingCircle';
import Modal from '../../../../components/common/Modal';
import PreCheckoutAsLoggedInUserForm from '../PreCheckoutAsLoggedInUserForm';
import PreCheckoutWithEmailForm from '../PreCheckoutWithEmailForm';
import { ProgressStepper } from '../ProgressStepper/ProgressStepper';
import { PropTypes } from 'prop-types';
import Text from '../../Text';
import { getLocalCurrentAndRecurringMonthlyDeadline } from '../../../../utility/dateHelper';
import style from './PreCheckoutStep.module.scss';

const className = 'c-PreCheckoutStep';
const baseClassNames = `${className} ${style[className]} overflow-y-auto`;
const containerClassName = style[`${className}__modal-container`];
const containerClassNames = `${className}__modal-container font-poppins ${containerClassName}`;

export const [LOGIN_FORM, SIGNUP_FORM] = ['login_form', 'signup_form'];

const PreCheckoutStep = (props) => {
  const {
    // modal state
    showModal,
    onClose,

    stepperProps,

    // UI Props
    title,
    subtitle,

    // login data
    formRef,
    onLoginSubmit,
    onLoginChange,
    loginError,
    loginLoading,
    loginInputErrors,

    // signup data
    onSignupEmailChange,
    onDialCodeIdChange,
    onPhoneNumberChange,
    handleCheckoutWithEmail,
    signupWithEmailErr,
    checkoutErrors,
    signupEmail,
    dialCodeId,
    phoneNumber,
    isSigningUpWithEmail,
    loadingText,
    isUserAlreadyEnrolled,
    showPhoneField,
    checkoutWithEmailCtaLabel,

    // error prompt data
    promptError,
    resetPromptError,

    //bundle
    isBundleFlow,

    // payment type
    isFreeCommunity,
    shouldPreventCheckoutRedirect,
    forceForm = null
  } = props;
  const [activeForm, setActiveForm] = useState(SIGNUP_FORM);
  const [show1on1Card, setShow1on1Card] = useState(false);
  const loadingTextRef = useRef(null);

  useEffect(() => {
    const item = loadingTextRef.current;
    if (item) {
      let animation = item.animate(
        [
          // keyframes
          { transform: 'translateY(0px)', opacity: '1' },
          { transform: 'translateY(1px)', opacity: '0.9' },
          { transform: 'translateY(4px)', opacity: '0.7' },
          { transform: 'translateY(9px)', opacity: '0.5' },
          { transform: 'translateY(14px)', opacity: '0.3' }
        ],
        {
          // timing options
          duration: 500
        }
      );

      /* The Animation has an eventHandler 
        refer to here: https://developer.mozilla.org/en-US/docs/Web/API/Animation
        and here: https://developer.mozilla.org/en-US/docs/Web/API/Animation/onfinish
      */
      animation.onfinish = () => {
        // change text
        item.innerText = loadingText;
        // and start the second animation
        item.animate(
          [
            // keyframes
            { transform: 'translateY(14px)', opacity: '0.3' },
            { transform: 'translateY(9px)', opacity: '0.5' },
            { transform: 'translateY(4px)', opacity: '0.7' },
            { transform: 'translateY(1px)', opacity: '0.9' },
            { transform: 'translateY(0px)', opacity: '1' }
          ],
          {
            // timing options
            duration: 500
          }
        );
      };
    }
  }, [loadingText, loadingTextRef]);

  const renderForm = () => {
    if (isSigningUpWithEmail) {
      return (
        <>
          <LoadingCircle />
          {loadingText && (
            <p
              className={style[`${className}__loading_text`]}
              ref={loadingTextRef}>
              {loadingTextRef && loadingText}
              {/* Keep it blank here since the useEffect will render the text along with the animation */}
            </p>
          )}
        </>
      );
    }

    let comparedForm = activeForm;
    if (forceForm) {
      comparedForm = forceForm;
    }
    switch (comparedForm) {
      case LOGIN_FORM:
        return (
          <PreCheckoutAsLoggedInUserForm
            showSignUpOffer={!forceForm}
            formRef={formRef}
            onLoginSubmit={onLoginSubmit}
            onLoginChange={onLoginChange}
            loginError={loginError}
            deselectCheckoutAsLoggedInUser={() =>
              setActiveForm(SIGNUP_FORM)
            }
            signupEmail={signupEmail}
            phoneNumber={phoneNumber}
            dialCodeId={dialCodeId}
            loginLoading={loginLoading}
            show1on1Card={show1on1Card}
            loginInputErrors={loginInputErrors}
            showPhoneField={showPhoneField}
          />
        );
      case SIGNUP_FORM:
        return (
          <PreCheckoutWithEmailForm
            showSignInOffer={!forceForm}
            selectCheckoutAsLoggedInUser={() => setActiveForm(LOGIN_FORM)}
            checkoutWithEmail={handleCheckoutWithEmail}
            onSignupEmailChange={onSignupEmailChange}
            onDialCodeIdChange={onDialCodeIdChange}
            onPhoneNumberChange={onPhoneNumberChange}
            signupWithEmailErr={signupWithEmailErr}
            checkoutErrors={checkoutErrors}
            signupEmail={signupEmail}
            dialCodeId={dialCodeId}
            phoneNumber={phoneNumber}
            isSigningUpWithEmail={isSigningUpWithEmail}
            loadingText={loadingText}
            show1on1Card={show1on1Card}
            showPhoneField={showPhoneField}
            checkoutWithEmailCtaLabel={checkoutWithEmailCtaLabel}
            isFreeCommunity={isFreeCommunity}
            shouldPreventCheckoutRedirect={shouldPreventCheckoutRedirect}
          />
        );
      default:
        return null;
    }
  };

  useEffect(() => {
    const { isDeadlineDay } =
      getLocalCurrentAndRecurringMonthlyDeadline(11);
    const show1on1Card = isDeadlineDay && !isBundleFlow;
    setShow1on1Card(show1on1Card);
  }, [isBundleFlow]);

  // if user is already enrolled, show the login form when they try to sign up with email
  useEffect(() => {
    if (activeForm === SIGNUP_FORM && isUserAlreadyEnrolled) {
      setActiveForm(LOGIN_FORM);

      //delegating to task queue so that it executes after state updates and modal is updated
      const timerId = setTimeout(() => {
        formRef.current.email.value = signupEmail;
      }, 0);

      return () => clearTimeout(timerId);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isUserAlreadyEnrolled]);

  return (
    <div className={baseClassNames}>
      <Modal
        customContainerClass={containerClassNames}
        open={showModal}
        // leftIcon={
        //   <div className="w-24 h-24 mb-24 text-center">
        //     <NextImage {...darkLogoImageData} />
        //   </div>
        // }
        // showLeftIcon={true}
        onClose={onClose}>
        {/* LOGO */}
        {/* <div className="mb-24 text-center">
          <NextImage {...darkLogoImageData} />
        </div> */}
        <div>
          {stepperProps?.steps?.length && (
            <ProgressStepper {...stepperProps} />
          )}
          <div className="flex flex-col mt-24 mb-32 ">
            {title && (
              <Text
                text={title}
                customClassName={
                  'font-poppins text-20 text-dark-33 font-semibold'
                }
              />
            )}
            {subtitle && (
              <Text
                text={subtitle}
                customClassName={'font-poppins text-14 text-dark-66'}
              />
            )}
          </div>
          {renderForm()}
          {activeForm === SIGNUP_FORM && (
            <>
              <div
                className={`${style[`${className}__separator`]} hidden`}
              />
              <div
                className={`${
                  style[`${className}__term-and-condition`]
                } hidden`}>
                By continuing, you agree with Nas Academy’s{' '}
                <Link
                  title="Terms and Condition Page"
                  href="/enrollment-terms"
                  target="_blank"
                  rel="noopener noreferrer">
                  Terms & Conditions
                </Link>
              </div>
            </>
          )}
        </div>
      </Modal>
      {promptError && (
        <Modal
          onClose={resetPromptError}
          open={true}
          customContainerClass={style[`${className}__modal`]}>
          <CheckoutErrorPrompt
            onClose={resetPromptError}
            message={promptError}
          />
        </Modal>
      )}
    </div>
  );
};

PreCheckoutStep.defaultProps = {
  showModal: false,
  isUserLoggedIn: false,
  loginLoading: false,
  isSigningUpWithEmail: false,
  isSubmittingSignup: false,
  isBundleFlow: false,
  loadingText: '',
  title: '',
  subtitle: ''
};

PreCheckoutStep.propTypes = {
  showModal: PropTypes.bool,
  onClose: PropTypes.func,
  isCheckingOutAsLoggedInUser: PropTypes.bool,
  selectCheckoutAsLoggedInUser: PropTypes.func,
  deselectCheckoutAsLoggedInUser: PropTypes.func,
  handleCheckoutWithEmail: PropTypes.func,
  formRef: PropTypes.object,
  onLoginSubmit: PropTypes.func,
  onLoginChange: PropTypes.func,
  loginError: PropTypes.string,
  loginLoading: PropTypes.bool,
  onSignupEmailChange: PropTypes.func,
  signupWithEmailErr: PropTypes.string,
  signupEmail: PropTypes.string,
  isSigningUpWithEmail: PropTypes.bool,
  loadingText: PropTypes.string,
  isSubmittingSignup: PropTypes.bool,
  isBundleFlow: PropTypes.bool,
  loginInputErrors: PropTypes.object,
  title: PropTypes.string,
  subtitle: PropTypes.string,
  isFreeCommunity: PropTypes.bool
};

export default PreCheckoutStep;
