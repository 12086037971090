import PropTypes from 'prop-types';
import React, { Children } from 'react';

export const ProgressStepper = (props) => {
  const { steps, activeStep } = props;
  const className = 'c-ProgressStepper';
  const renderStepLine = (completed) => {
    const classNames = completed ? `bg-yellow-80` : `bg-grey-netural90`;
    return (
      <hr
        className={`w-full h-[3px] rounded transition-all duration-1000 mx-18 border-0 ${classNames}`}
      />
    );
    // return <hr className={`w-full h-[1px] border-2 ${classNames}`} />;
  };
  const renderStep = (step, index) => {
    const commonClassNames =
      'w-24 h-24 min-w-[24px] min-h-[24px] rounded-full flex items-center  justify-center transition-all duration-1000 font-poppins text-12 text-dark-33';
    const finishStepClassNames = `bg-yellow-80`;
    const incompleteStepClassNames = `bg-grey-netural90`;

    const isFinishedStep = index <= activeStep;
    const isCurrentStep = index === activeStep;
    const classNames = isFinishedStep
      ? finishStepClassNames
      : incompleteStepClassNames;
    const lastStep = index === steps.length - 1;
    const stepLineCompleted = index + 1 <= activeStep;

    return (
      <>
        <div
          className={`${className}__progress_step ${commonClassNames} ${classNames}`}>
          <span>{index + 1}</span>
        </div>
        {isCurrentStep && (
          <div className="ml-8 font-medium uppercase font-poppins text-12 text-dark-33 whitespace-nowrap">
            <span>{step}</span>
          </div>
        )}
        {!lastStep && renderStepLine(stepLineCompleted)}
      </>
    );
  };

  return (
    <div className={`${className} h-56 flex flex-row items-center`}>
      {Children.toArray(steps.map(renderStep))}
    </div>
  );
};

ProgressStepper.propTypes = {
  steps: PropTypes.arrayOf(PropTypes.string),
  activeStep: PropTypes.number
};

ProgressStepper.defaultProps = {};
