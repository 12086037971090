import React from 'react';

function AlertIcon() {
  const className = 'c-AlertIcon';
  return (
    <svg
      width="100"
      height="100"
      fill="none"
      viewBox="0 0 100 100"
      className={className}>
      <circle cx="50" cy="50" r="50" fill="#FFF4CC" opacity="0.5"></circle>
      <circle cx="49.375" cy="49.375" r="40.625" fill="#FFF4CC"></circle>
      <g clipPath="url(#clip0-c-AlertIcon)">
        <path
          fill="#FBC91B"
          d="M50 81.25c17.259 0 31.25-13.991 31.25-31.25S67.259 18.75 50 18.75 18.75 32.741 18.75 50 32.741 81.25 50 81.25z"></path>
        <g fill="#FFF4CC" clipPath="url(#clip1-c-AlertIcon)">
          <path d="M50.889 68a3.889 3.889 0 100-7.778 3.889 3.889 0 000 7.778zM54.778 53.111V36.89a3.889 3.889 0 10-7.778 0V53.11a3.889 3.889 0 107.778 0z"></path>
        </g>
      </g>
      <defs>
        <clipPath id="clip0-c-AlertIcon">
          <path
            fill="#fff"
            d="M0 0H87.5V87.5H0z"
            transform="translate(6.25 6.25)"></path>
        </clipPath>
        <clipPath id="clip1-c-AlertIcon">
          <path
            fill="#fff"
            d="M0 0H35V35H0z"
            transform="translate(33 33)"></path>
        </clipPath>
      </defs>
    </svg>
  );
}

export default AlertIcon;
