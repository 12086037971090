import * as yup from 'yup';

import { useEffect, useState } from 'react';
import {
  getTrackingInformationForBE,
  trackBeginCheckout,
  trackLead,
  trackTikTokIdentity
} from '../../utility/analytics';
import {
  CHECKOUT_TYPE_COMMUNITY,
  CHECKOUT_TYPE_COURSE,
  FREE_ONDEMAND_COURSES,
  SIGN_UP_ACCESS_TOKEN_SESSION_KEY,
  SIGN_UP_EMAIL_SESSION_KEY,
  SIGN_UP_ID_SESSION_KEY,
  SIGN_UP_PHONE_NUMBER_SESSION_KEY,
  SIGN_UP_SLUG_SESSION_KEY,
  SIGN_UP_USER_CREDITS_SESSION_KEY,
  USER_ID_SESSION_KEY,
  SIGN_UP_FULL_NAME_SESSION_KEY,
  SIGN_UP_PRICE_ID
} from '../../utility/checkoutConstants';
import {
  getCommunityCheckoutRoute,
  getCourseSignupRoute
} from '../../utility/routesHelper';

import { communitiesSignUpService } from '@/services/communitiesSignUpService';
import { enrollToCourseForFreeService } from '@/services/courseService';
import { getDialCodeById } from '@/utility/formHelpers';
import { getTimezoneId } from '../../utility/dateHelper';
import { initiateCheckoutWithEmail } from '@/services/signUpService';
import { isCheckoutTypeValid } from '../../contexts/UnifiedCheckoutContext/helper';
import { showToast } from '@/components/common/ToastContainer';
import { validateOptionalPhoneNumberWithDialCode } from '@/utility/validation';
import { useRouter } from 'next/router';
import usePaymentSuccessRedirectLink from '../usePayment/usePaymentSuccessRedirectLink';
import useSetUserCountryDialCodeId from '../useSetUserCountryDialCodeId';
import { removeAllToasts } from '@/components/common/ToastContainer/ToastContainer';

const validationSchema = yup.object().shape({
  email: yup.string().email('Invalid Email').required('Email is required'),
  dialCodeId: yup.string().nullable(),
  phoneNumber: yup
    .string()
    .test(
      'phoneNumberValidation',
      'Invalid phone number!',
      function (value) {
        return validateOptionalPhoneNumberWithDialCode(
          value,
          this.parent['dialCodeId']
        );
      }
    )
    .nullable()
});
const ALREADY_ENROLLED_ERROR_CODE = 'EnrolmentNotAllowed';

const LOADING_TEXT_VALIDATE = 'Validating the information';
const LOADING_TEXT_REDIRECT =
  'Redirecting you to the Checkout page. Please wait';

const LOADING_TEXT_REDIRECT_FREE_WORKSHOP =
  'Redirecting you to the learning portal. Please wait';

const useInitCheckoutWithEmail = ({
  isFreeWorkshop,
  isCommunity,
  shouldPreventCheckoutRedirect,
  sourceInfo,
  requestor = '',
  discountCodeFromQueryParams = '',
  entityDiscountCodeFromQueryParams = ''
}) => {
  const [signupEmail, setSignupEmail] = useState('');
  const [phoneNumber, setPhoneNumber] = useState('');
  const [fullName, setFullName] = useState('');
  const [dialCodeId, setDialCodeId] = useState('');
  const [redirectLink, setRediretLink] = useState(null);
  const [signUpErrors, setSignUpErrors] = useState({});
  const [signupWithEmailErr, setSignupWithEmailErr] = useState('');
  const [isUserAlreadyEnrolled, setIsUserAlreadyEnrolled] =
    useState(false);
  const [signupWithEmailSuccess, setSignupWithEmailSuccess] =
    useState(false);
  const [isSigningUpWithEmail, setIsSigningUpWithEmail] = useState(false);
  const [loadingText, setLoadingText] = useState('');
  const [
    shouldStartOutsideRedirectFlow,
    setShouldStartOutsideRedirectFlow
  ] = useState(false);

  const [showFreeSignupSuccessModal, setShowFreeSignupSuccessModal] =
    useState(false);
  // TODO - use showFreeSignupSuccessModal and closeFreeSignupSuccessModal for b2b signups and free workshops

  const closeFreeSignupSuccessModal = () =>
    setShowFreeSignupSuccessModal(false);

  const router = useRouter();

  const { getRedirectLinkAsync, loadingRedirectLink } =
    usePaymentSuccessRedirectLink();

  /**
   * Call initiate checkout with email API, and save value of email and signup id in session to be reterived from checkout page.
   * On success from initiate checkout with email API, redirect to checkout page.
   * Use checkoutType to identify if checkout is for course purchase of bundle purchase and call relevant API based on that.
   *
   * @param {*} email string
   * @param {*} checkoutType string | CHECKOUT_TYPE_COURSE || CHECKOUT_TYPE_BUNDLE
   * @param {*} courseOfferingId string
   * @param {*} bundleData object
   * @param {*} bundleData.bundleId string
   * @param {*} bundleData.bundleSlug string
   * @param {*} bundleData.bundleCourses [string]
   * @param {*} bundleData.isGiftPurchase boolean
   *
   */
  const initCheckoutWithEmail = async (params) => {
    const {
      groupCode,
      email,
      slug,
      courseCode,
      checkoutType,
      courseOfferingId,
      communityCode,
      // bundleData,
      // bundleCourses,
      // autoCreateBundle,
      dialCodeId,
      phoneNumber,
      fullName,
      interval = 'month',
      intervalCount = 1,
      priceId,
      withRedirect = true,
      isDirectSignUpEmail = false,
      actionType,
      shouldSendTransactionId
    } = params;

    if (!isCheckoutTypeValid(checkoutType)) return;

    setIsSigningUpWithEmail(true);
    setLoadingText(LOADING_TEXT_VALIDATE);
    setSignupWithEmailErr('');
    setSignUpErrors({});
    const LOADING_TEXT_ENROLL = isFreeWorkshop
      ? 'Enrolling you to the new Workshop'
      : isCommunity
      ? 'Enrolling you to the new Community'
      : 'Enrolling you in';
    const isValid = await validationSchema
      .validate({ email, phoneNumber, dialCodeId })
      .then(() => true)
      .catch((validationErr) => {
        const { type, message } = validationErr;

        if (Array.isArray(validationErr.inner)) {
          let errors = {};
          validationErr.inner.forEach(
            ({ path, message }) => (errors[path] = message)
          );
          setSignUpErrors(errors);
        }
        if (type === 'phoneNumberValidation') {
          setSignUpErrors({ phone: message });
        }
        return false;
      });

    if (!isValid) {
      setIsSigningUpWithEmail(false);
      setLoadingText('');
      return;
    }

    // Setting local context of variables
    setPhoneNumber(phoneNumber);
    setSignupEmail(email);
    setFullName(fullName);
    // NOTE: This happens in the useEffect so might get into a loop...
    // Need to check
    setDialCodeId(dialCodeId);

    // initialize the following data to be retereived from API.
    let bookingId = '';
    let userId = '';
    let accessToken = '';
    let creditAmtCents = '';
    let trackingData = getTrackingInformationForBE({
      courseCode,
      groupCode
    });
    let allowCourseForFree = false;

    let beginCheckoutTrackingPayload = {
      email,
      communityCode
    };

    const formattedPhoneNumber = phoneNumber?.includes('+')
      ? phoneNumber
      : `${getDialCodeById(dialCodeId)}${phoneNumber}`;

    let resData;

    if (checkoutType === CHECKOUT_TYPE_COURSE) {
      // Sign up Id return from sign up email api call
      const payload = {
        timezone: getTimezoneId(),
        courseCode: courseOfferingId,
        courseType: 'general',
        basicInfo: [
          {
            studentInfo:
              phoneNumber && phoneNumber.length !== 0 && dialCodeId
                ? {
                    email,
                    phone: formattedPhoneNumber
                  }
                : {
                    email
                  }
          }
        ],
        trackingData: trackingData,
        isDirectSignUpEmail,
        requestor
      };

      const { data, error } = await initiateCheckoutWithEmail(payload);
      if (error) {
        if (error?.code === ALREADY_ENROLLED_ERROR_CODE) {
          setLoadingText('');
          setIsUserAlreadyEnrolled(true);
          setIsSigningUpWithEmail(false);
          return;
        }
        // TODO: BE should be giving me a correct code....
        if (error?.code === undefined) {
          setLoadingText('');
          try {
            const errs = JSON.parse(error?.message.replace(/'/g, '"'))
              ?.basicInfo?.studentInfo;
            setSignUpErrors(errs);
            showToast({ text: errs, type: 'error' });
          } catch (e) {
            const errs = error?.message.replace(/'/g, '"');
            setSignUpErrors(errs);
            showToast({ text: errs, type: 'error' });
          }
          setIsSigningUpWithEmail(false);
        }

        setSignupWithEmailErr(error?.message);
        setIsSigningUpWithEmail(false);
        return false;
      }

      const {
        signup_id,
        access_token,
        credit_amount,
        learnerId,
        isFreeAccessEligible
      } = data;
      resData = { ...data };

      bookingId = signup_id;
      accessToken = access_token;
      creditAmtCents = credit_amount;
      userId = learnerId;
      allowCourseForFree = !!isFreeAccessEligible;
    }

    if (checkoutType === CHECKOUT_TYPE_COMMUNITY) {
      // Sending communityCode as courseOfferingId
      const payload = {
        timezone: getTimezoneId(),
        communityCode: courseOfferingId ?? communityCode,
        phoneNumber: phoneNumber ? formattedPhoneNumber : null,
        fullName: fullName,
        email,
        isDirectSignUpEmail,
        interval,
        intervalCount,
        trackingData: trackingData,
        requestor: requestor || ''
      };

      if (priceId) {
        payload.priceId = priceId;
      }

      if (shouldSendTransactionId) {
        payload.previous_transaction_id =
          sessionStorage.getItem(SIGN_UP_ID_SESSION_KEY) ?? '';
      }

      // send source info to the BE, can be passed from useStartCheckout hook with required data
      if (sourceInfo) payload.source = sourceInfo;

      const res = await communitiesSignUpService(payload);
      const { error, ...data } = res;
      if (error) {
        // TODO: Add the if already enrolled condition here
        if (error?.code === ALREADY_ENROLLED_ERROR_CODE) {
          setLoadingText('');
          removeAllToasts();
          showToast({
            text: 'You are already enrolled or have a pending application.',
            type: 'error'
          });
          setIsUserAlreadyEnrolled(true);
          setIsSigningUpWithEmail(false);
          return { error: error?.info };
        }
        setSignupWithEmailErr(error?.message);
        setIsSigningUpWithEmail(false);
        return { error: error?.info || error };
      }

      const { id, access_token, learnerId, credit_amount = 0 } = data;

      bookingId = id;
      accessToken = access_token;
      creditAmtCents = credit_amount;
      userId = learnerId;
      resData = { ...data };

      if (payload?.source) {
        beginCheckoutTrackingPayload = {
          ...beginCheckoutTrackingPayload,
          sourceInfoType: payload?.source?.type,
          sourceInfoOrigin: payload?.source?.origin
        };
      }
    }

    // Set values in session to be retrieved on checkout page.
    sessionStorage.setItem(SIGN_UP_FULL_NAME_SESSION_KEY, fullName ?? '');
    sessionStorage.setItem(SIGN_UP_PHONE_NUMBER_SESSION_KEY, phoneNumber);
    sessionStorage.setItem(SIGN_UP_EMAIL_SESSION_KEY, email);
    sessionStorage.setItem(SIGN_UP_ID_SESSION_KEY, bookingId);
    sessionStorage.setItem(SIGN_UP_SLUG_SESSION_KEY, slug);
    sessionStorage.setItem(USER_ID_SESSION_KEY, userId);
    sessionStorage.setItem(SIGN_UP_PRICE_ID, priceId);
    sessionStorage.setItem(SIGN_UP_ACCESS_TOKEN_SESSION_KEY, accessToken);
    sessionStorage.setItem(
      SIGN_UP_USER_CREDITS_SESSION_KEY,
      creditAmtCents
    );
    // track analytics
    trackLead({
      email,
      phoneNumber,
      interval,
      intervalCount,
      leadType: 'community leads'
    });

    trackBeginCheckout(beginCheckoutTrackingPayload);
    trackTikTokIdentity({ email });

    if (withRedirect) {
      // redirect to checkout
      let redirectRoute = '';

      if (checkoutType === CHECKOUT_TYPE_COURSE) {
        if (
          isFreeWorkshop ||
          FREE_ONDEMAND_COURSES.includes(courseOfferingId) ||
          allowCourseForFree
        ) {
          setLoadingText(LOADING_TEXT_ENROLL);
          const params = {
            courseCode: courseOfferingId,
            currency: 'USD',
            deductAmount: 0,
            paymentAmount: 0,
            courseType: 'general',
            slug,
            discount: false
          };

          const { error, data: enrollmentData } =
            await enrollToCourseForFreeService(params);
          if (error || !enrollmentData) {
            setSignupWithEmailErr(error?.message);
            setLoadingText('');
            setIsSigningUpWithEmail(false);
          }

          if (!error && enrollmentData && allowCourseForFree) {
            if (bookingId) {
              const { error: err, link: redirectLink } =
                await getRedirectLinkAsync({
                  bookingId,
                  ...(communityCode && { communityCode })
                });
              if (!err && redirectLink) {
                setRediretLink(redirectLink);
              }
            }
            setSignupWithEmailSuccess(true);
            setShowFreeSignupSuccessModal(true);
            setIsSigningUpWithEmail(false);
          }
          // Wait for 2 secs before trying the get the redirect link
          if (bookingId) {
            setLoadingText(LOADING_TEXT_REDIRECT_FREE_WORKSHOP);

            const { error: err, link: redirectLink } =
              await getRedirectLinkAsync({ bookingId });
            if (err || !redirectLink) {
              setSignupWithEmailErr(error?.message);
              setLoadingText('');
              setIsSigningUpWithEmail(false);
            } else {
              setLoadingText(LOADING_TEXT_REDIRECT_FREE_WORKSHOP);
              if (isFreeWorkshop) {
                setRediretLink(redirectLink);
                setSignupWithEmailSuccess(true);
                setShowFreeSignupSuccessModal(true);
                // Close the modal.
                setIsSigningUpWithEmail(false);
                return;
              } else {
                router.push(redirectLink);
              }
              // If Redirect then we don't want to close the modal Loading Scren..
              // Commenting it out to remember that it goes here.
              // router.push(redirectLink);
            }
          }
        } else {
          redirectRoute = getCourseSignupRoute(courseOfferingId);
        }
      }

      if (checkoutType === CHECKOUT_TYPE_COMMUNITY) {
        redirectRoute = getCommunityCheckoutRoute({
          communityCode: communityCode ?? courseOfferingId,
          interval,
          intervalCount,
          step: 'apply',
          communitySlug: slug,
          actionType,
          sourceInfo,
          requestor: requestor,
          discountCode: discountCodeFromQueryParams,
          entityDiscountCode: entityDiscountCodeFromQueryParams
        });
      }

      setLoadingText(LOADING_TEXT_REDIRECT);
      setRediretLink(redirectRoute);

      if (!shouldPreventCheckoutRedirect) {
        router.push(redirectRoute);
        return;
      } else {
        setShouldStartOutsideRedirectFlow(true);
      }
    }

    return resData;
  };

  useEffect(() => {
    setSignupWithEmailErr('');
    setSignUpErrors({});
  }, [signupEmail, dialCodeId, phoneNumber]);

  useSetUserCountryDialCodeId({ dialCodeId, setDialCodeId });

  return {
    signupEmail,
    setSignupEmail,
    dialCodeId,
    setDialCodeId,
    phoneNumber,
    setPhoneNumber,
    signUpErrors,
    setSignUpErrors,
    signupWithEmailErr,
    setSignupWithEmailErr,
    signupWithEmailSuccess,
    setSignupWithEmailSuccess,
    redirectLink,
    isSigningUpWithEmail,
    loadingText,
    setLoadingText,
    setIsSigningUpWithEmail,
    isUserAlreadyEnrolled,
    setIsUserAlreadyEnrolled,
    initCheckoutWithEmail,
    loadingRedirectLink,
    showFreeSignupSuccessModal,
    closeFreeSignupSuccessModal,

    // for free community direct learning portal redirect
    shouldStartOutsideRedirectFlow,
    setShouldStartOutsideRedirectFlow
  };
};

export default useInitCheckoutWithEmail;
