import config from '../../utility/config';
import { getRedirectLinkOnPaymentSuccess } from '../../services/signUpService';
import { useState } from 'react';
import { retryablePromise } from '@/pages/portal/utils/promise';

export const CTA_EVENT_PURCHASE = 'Complete event purchase';
export const CTA_MEMBER_PORTAL = 'Go To Member Portal';

const usePaymentSuccessRedirectLink = () => {
  const [loadingRedirectLink, setLoadingRedirectLink] = useState(false);
  const [redirectLink, setRedirectLink] = useState('');
  const [whatsappInvitationLink, setWhatsappInvitationLink] = useState('');
  const [redirectCTALabel, setRedirectCTALabel] = useState('');
  const [whatsappGroupLink, setWhatsappGroupLink] = useState('');
  const [discordGroupLink, setDiscordGroupLink] = useState('');
  const [communityPlatforms, setCommunityPlatforms] = useState([]);
  const [learnerId, setLearnerId] = useState('');
  const [isLearnerNew, setIsLearnerNew] = useState(true);

  const getRedirectLinkAsync = async (params) => {
    try {
      const res = await retryablePromise(() =>
        getRedirectLinkOnPaymentSuccess(params)
      );
      const { error, data } = res;

      if (error) throw new Error(error);

      // Get redirect link from response data
      const resLink = data?.link;
      if (resLink) {
        setRedirectLink(resLink);
      }

      if (data?.whatsappInvitationLink) {
        setWhatsappInvitationLink(data?.whatsappInvitationLink);
      }

      if (data?.isRedirectToEventCheckout) {
        setRedirectCTALabel(CTA_EVENT_PURCHASE);
      } else {
        setRedirectCTALabel(CTA_MEMBER_PORTAL);
      }

      // get student group whatsapp link and discord link from response data
      const _whatsappGroupLink = data?.whatsappGroupLink;
      if (_whatsappGroupLink) setWhatsappGroupLink(_whatsappGroupLink);

      const _discordGroupLink = data?.discordGroupLink;
      if (_discordGroupLink) setDiscordGroupLink(_discordGroupLink);

      if (data?.communityPlatforms)
        setCommunityPlatforms(data?.communityPlatforms);

      const _learnerId = data?.learnerId;
      if (_learnerId) setLearnerId(_learnerId);
      const _isLearnerNew = data?.isLearnerNew;
      setIsLearnerNew(_isLearnerNew);
      return { error, link: resLink };
    } catch (error) {
      return { error };
    } finally {
      setLoadingRedirectLink(false);
    }
  };

  /**
   *
   * @param {Object} params
   * @param {String} params.bookingId
   * @param {Boolean} params.isCommunity
   * @param {Number} delay
   */
  const getRedirectLink = (params, delay = 2000) => {
    setLoadingRedirectLink(true);
    setTimeout(async () => await getRedirectLinkAsync(params), delay);
  };

  const goToRedirectLink = () => {
    const redirectTo = redirectLink || config.memberPortalPath;
    window.location = redirectTo;
  };
  return {
    getRedirectLink,
    getRedirectLinkAsync,
    loadingRedirectLink,
    redirectLink,
    redirectCTALabel,
    goToRedirectLink,
    whatsappGroupLink,
    discordGroupLink,
    communityPlatforms,
    learnerId,
    isLearnerNew,
    whatsappInvitationLink
  };
};

export default usePaymentSuccessRedirectLink;
