import { countryList } from '@/data/CoursePage/wisenetMapCountryList';
import { getUserCountryData } from '@/services/paymentService';
import { useEffect } from 'react';

const useSetUserCountryDialCodeId = (props) => {
  const { dialCodeId, setDialCodeId } = props;

  useEffect(() => {
    const setDialCodeIdAsync = async () => {
      if (
        dialCodeId === '' ||
        dialCodeId === null ||
        dialCodeId === undefined
      ) {
        const { data, error } = await getUserCountryData();
        if (error) {
          console.error('Error in retrieving country');
          return;
        }
        const countryFromApi = data?.country;
        if (countryFromApi) {
          const countryFromList = countryList.find(
            (c) => c.name === countryFromApi
          );
          const countryId = countryFromList?.id;
          if (countryId) setDialCodeId(countryId);
        }
      }
    };
    setDialCodeIdAsync();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dialCodeId]);
};

export default useSetUserCountryDialCodeId;
