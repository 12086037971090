import {
  CHECKOUT_TYPE_BUNDLE,
  CHECKOUT_TYPE_COMMUNITY,
  CHECKOUT_TYPE_COURSE,
  COUPON_SESSION_KEY,
  DISCOUNT_TYPE_AMOUNT_OFF,
  DISCOUNT_TYPE_PERCENT_OFF,
  GIFT_CODE_TYPE,
  SIGN_UP_ACCESS_TOKEN_SESSION_KEY,
  SIGN_UP_EMAIL_SESSION_KEY,
  SIGN_UP_PHONE_NUMBER_SESSION_KEY,
  SIGN_UP_ID_SESSION_KEY,
  SIGN_UP_USER_CREDITS_SESSION_KEY,
  USER_ID_SESSION_KEY,
  SIGN_UP_FULL_NAME_SESSION_KEY
} from '../../utility/checkoutConstants';

import { getShortMonthDate } from '@/utility/dateHelper';

export const isCheckoutTypeValid = (checkoutType) =>
  [
    CHECKOUT_TYPE_COURSE,
    CHECKOUT_TYPE_BUNDLE,
    CHECKOUT_TYPE_COMMUNITY
  ].includes(checkoutType);

// Get data saved in session for checkout on previous steps
export const getCheckoutSessionValues = () => {
  if (typeof window === 'undefined') return {};

  const signupEmail = sessionStorage.getItem(SIGN_UP_EMAIL_SESSION_KEY);

  const signupPhoneNumber = sessionStorage.getItem(
    SIGN_UP_PHONE_NUMBER_SESSION_KEY
  );

  const signupFullName = sessionStorage.getItem(
    SIGN_UP_FULL_NAME_SESSION_KEY
  );

  const signupId = sessionStorage.getItem(SIGN_UP_ID_SESSION_KEY);
  const userId = sessionStorage.getItem(USER_ID_SESSION_KEY);

  // userCreditAmount should be in dollars and not cents
  const sessionCreditValueInCents = sessionStorage.getItem(
    SIGN_UP_USER_CREDITS_SESSION_KEY
  );
  // Divide by 100 to convert to dollars
  const userCreditAmount = sessionCreditValueInCents
    ? parseInt(sessionCreditValueInCents, 10) / 100
    : 0;

  // Autoapply this coupon if present in session
  const couponQueryParam =
    sessionStorage.getItem(COUPON_SESSION_KEY) || '';

  return {
    signupEmail,
    signupPhoneNumber,
    signupFullName,
    signupId,
    userId,
    userCreditAmount,
    couponQueryParam
  };
};

// Clear All Checkout releated session data on completion.
export const clearSessionData = () => {
  sessionStorage.removeItem(SIGN_UP_EMAIL_SESSION_KEY);
  sessionStorage.removeItem(SIGN_UP_ID_SESSION_KEY);
  sessionStorage.removeItem(SIGN_UP_USER_CREDITS_SESSION_KEY);
  sessionStorage.removeItem(SIGN_UP_ACCESS_TOKEN_SESSION_KEY);
  sessionStorage.removeItem(SIGN_UP_FULL_NAME_SESSION_KEY);
  sessionStorage.removeItem(SIGN_UP_PHONE_NUMBER_SESSION_KEY);
};

// Get slug by payment status for url
// export const getPaymentSlugByStatus = (paymentStatus) => {
//   if (paymentStatus === PAYMENT_SUCCESS) return 'payment-success';
//   if (paymentStatus === PAYMENT_FAILURE) return 'payment-failure';
//   if (paymentStatus === PAYMENT_PENDING) return 'payment-pending';
// };

// Get Payment metadata
export const getPaymentInformation = (paymentOptions, totalUsers = 1) => {
  // idx is the index at which payments data will be available in paymentOptions.paymentList array for a given number of users i.e totalUsers
  const idx = totalUsers;
  const paymentOption = paymentOptions?.paymentList?.[idx] ?? null;
  const discountEnabled = paymentOption?.discountEnabled || false;
  const depositEnabled = paymentOption?.depositEnabled || false;
  const depositAmount = paymentOption?.depositAmount || 0;
  const totalAmount = paymentOption?.totalAmount || 0;
  const fullAmount = paymentOption?.fullAmount || 0;
  const installmentCurrency = paymentOption?.currency;
  const nextInstallmentAmount = paymentOption?.installments?.[1];
  const nextInstallmentDate = getShortMonthDate(
    paymentOption?.nextInstallmentDate
  );

  return {
    paymentOption,
    discountEnabled,
    depositEnabled,
    depositAmount,
    totalAmount,
    fullAmount,
    installmentCurrency,
    nextInstallmentAmount,
    nextInstallmentDate
  };
};

// Get discount value in USD
export const getDiscountCodeAmount = ({
  discountDetails,
  discountApplied,
  totalAmount
}) => {
  if (!discountDetails || !discountApplied) return 0;

  const { type, value } = discountDetails;

  if (type === DISCOUNT_TYPE_PERCENT_OFF) {
    return totalAmount * (value / 100);
  } else if (type === DISCOUNT_TYPE_AMOUNT_OFF) {
    // discount value is returned in cents
    const discountValueInUsd = value / 100;
    return discountValueInUsd;
  } else {
    return 0;
  }
};

// Get message to display to the user if gift amount greater than purchase price.
export const computePostGiftCodeAppliedMessage = (
  discountApplied,
  discountDetails,
  totalAmount
) => {
  let msg = '';

  if (discountApplied && discountDetails) {
    const { codeType, type, value } = discountDetails;
    const discountAmount = value / 100; // convert cents to usd
    const priceDiff = discountAmount - totalAmount;
    const surplusGiftAmountCondition =
      codeType === GIFT_CODE_TYPE &&
      type === DISCOUNT_TYPE_AMOUNT_OFF &&
      priceDiff > 0; // means discountMount is greater than the toal amount

    if (surplusGiftAmountCondition) {
      msg = [
        { text: 'Congratulations! ', isBolder: true },
        { text: 'You can enroll to this course for ' },
        { text: 'FREE! ', isBolder: true },
        { text: 'The surplus amount of ' },
        { text: `USD ${priceDiff} `, isBolder: true },
        { text: 'from the Gift Code will be added to your credits.' }
      ];
    }
  }

  return msg;
};

export const getCalculatedFinalAmount = ({
  useUserCredit,
  userCreditAmount,
  discountApplied,
  discountCodeAmt,
  totalAmount,
  fullAmount
}) => {
  if (useUserCredit || discountApplied) {
    const userCreditDiscount = useUserCredit ? userCreditAmount : 0;
    const calculatedAmountAfterDiscount =
      totalAmount - userCreditDiscount - discountCodeAmt;

    return calculatedAmountAfterDiscount < 0
      ? 0
      : calculatedAmountAfterDiscount;
  }

  return fullAmount;
};

export const getCheckoutParamsFromRouter = (router) => {
  // get data from URI
  const {
    type: checkoutType,
    bundleAction,
    bundleSlug,
    courseOfferingId,
    bundleCourses,
    autoCreateBundle,
    email,
    communityCode,
    interval,
    intervalCount,
    sourceInfoType,
    sourceInfoOrigin,
    xenditStatus,
    requestor
  } = router.query;

  return {
    checkoutType,
    bundleAction,
    bundleSlug,
    courseOfferingId,
    bundleCourses,
    autoCreateBundle,
    directSignUpEmail: email,
    communityCode,
    interval: interval !== undefined ? interval : 'month',
    intervalCount: intervalCount !== undefined ? intervalCount : 1,
    sourceInfoType,
    sourceInfoOrigin,
    xenditStatus,
    requestor
  };
};

// https://timleland.com/money-in-javascript/
// This handles the conversion with the floating point precision
// 32.8 => 3280
// 0 => 0
// 3,238.9 => 323890
export const convertDollarsToCents = (value) => {
  if (isNaN(value)) {
    return '';
  }
  try {
    value = (value + '').replace(/[^\d.-]/g, '');
    if (value && value.includes('.')) {
      value = value.substring(0, value.indexOf('.') + 3);
    }
    return value ? Math.round(parseFloat(value) * 100) : '';
  } catch {
    return '';
  }
};

export const getStripePublicKeyFromPaymentMethods = (paymentMethods) => {
  let output = {};
  for (let i = 0; i < paymentMethods.length; i++) {
    const item = paymentMethods[i];
    if (item.mode === 'stripe-india') {
      output = {
        mode: 'INDIA',
        publicKey: item.publicKey
      };
      break;
    } else if (item.mode === 'stripe') {
      output = {
        mode: 'GLOBAL',
        publicKey: item.publicKey
      };
      break;
    }
  }
  return output;
};
