import { getDialCodeById } from '@/utility/formHelpers';
import { validate } from '@/utility/validation';
import { useRef, useState } from 'react';

import { useAuthContext } from '../contexts/AuthContext';
import useInitCheckoutWithEmail from './checkout/useInitCheckoutWithEmail';
import { loginFormSchema } from './checkout/validationSchema';

const useStudentLogin = () => {
  const {
    isLoading,
    login,
    loginError,
    isLoggedIn,
    resetLoginError,
    user
  } = useAuthContext();

  const { phoneNumber, dialCodeId, setPhoneNumber, setDialCodeId } =
    useInitCheckoutWithEmail({ isFreeWorkshop: false });

  const formRef = useRef(null);
  const [errors, setErrors] = useState({});

  const onLoginChange = (e) => {
    resetLoginError();
    onLoginInputChange(e);
  };

  const onLoginInputChange = (e) => {
    if (errors[e.target.name]) {
      setErrors((prev) => ({ ...prev, [e.target.name]: '' }));
    }
    if (e.target.name === 'phone') {
      setPhoneNumber(e.target.value);
    }
    if (e.target.name === 'dialCodeId') {
      setDialCodeId(e.target.value);
    }
  };

  const onLoginSubmit = async (e, callback) => {
    e.preventDefault();
    let email = formRef.current?.email?.value ?? '';
    let password = formRef.current?.password?.value ?? '';
    let phoneNo = formRef.current?.phone?.value ?? phoneNumber;
    let realDialCodeId = formRef.current?.dialCodeId?.value ?? dialCodeId;
    const phone =
      (phoneNo &&
        phoneNo.length !== 0 &&
        realDialCodeId &&
        `${getDialCodeById(realDialCodeId)}${phoneNumber}`) ||
      '';
    let formData = {
      email,
      password
    };

    if (phone && phone.length !== 0) {
      formData = { ...formData, dialCodeId: realDialCodeId, phone };
    }

    const isValid = await validate({
      schema: loginFormSchema,
      formData,
      setErrors
    });
    if (!isValid) {
      return;
    }

    await login(email, password, callback);
  };

  return {
    formRef,
    onLoginSubmit,
    onLoginChange,
    loginError,
    loggedInSuccess: isLoggedIn,
    loginLoading: isLoading,
    loggedInUser: user?.learner || null,
    loginInputErrors: errors
  };
};

export default useStudentLogin;
