import React, { useMemo } from 'react';

import Button from '../../Button';
import FormField from '../../Form/FormField';
import LoadingCircle from '../../LoadingCircle/LoadingCircle';
// import OneOnOneCard from '../OneOnOneCard';
import { PropTypes } from 'prop-types';
import SocialLogins from '../../SocialLogins/SocialLogins';
import { dialCodeOptions } from '@/utility/formHelpers';
import style from './PreCheckoutWithEmailForm.module.scss';
/* eslint-disable jsx-a11y/click-events-have-key-events */
import { useAuthContext } from '@/contexts/AuthContext';
import { useUserSessionContext } from '@/contexts/UserSessionContext';

const PreCheckoutWithEmailForm = ({
  selectCheckoutAsLoggedInUser,
  checkoutWithEmail,
  onSignupEmailChange,
  onDialCodeIdChange,
  onPhoneNumberChange,
  signupWithEmailErr,
  checkoutErrors,
  signupEmail,
  dialCodeId,
  phoneNumber,
  isSigningUpWithEmail,
  loadingText,
  // show1on1Card,
  showPhoneField,
  checkoutWithEmailCtaLabel,
  showSignInOffer = true
}) => {
  const className = 'c-PreCheckoutWithEmailForm';

  const { isWebView } = useUserSessionContext();
  const { isLoading, handleSocialAuth, socialLoginError } =
    useAuthContext();

  const formatteddialCodeOptions = useMemo(
    () =>
      dialCodeOptions.reduce((result, { label, value }) => {
        if (value === Number(dialCodeId)) {
          result.push({ value, label: label.split(' ')[0] });
        } else {
          result.push({ label, value });
        }
        return result;
      }, []),
    [dialCodeId]
  );
  return (
    <div className={style[className]}>
      {/*Precheckout form here*/}
      {isSigningUpWithEmail ? (
        <>
          <LoadingCircle />
          {loadingText && (
            <p className={style[`${className}__loading_text`]}>
              {loadingText}
            </p>
          )}
        </>
      ) : (
        <>
          {!isWebView ? (
            <>
              <SocialLogins
                isSignup
                isLoading={isLoading}
                handleSocialAuth={handleSocialAuth}
                socialLoginError={socialLoginError}
                isRounded={true}
              />
              <div className={style[`${className}__or`]}>
                <div className={style[`${className}__or__text`]}>OR</div>
              </div>
            </>
          ) : null}
          <form
            className={style[`${className}__form-container`]}
            onSubmit={checkoutWithEmail}>
            <FormField
              fieldType="input"
              name="email"
              label={'Email'}
              onChange={(e) => {
                onSignupEmailChange(e.target.value);
              }}
              value={signupEmail}
              error={signupWithEmailErr || checkoutErrors['email']}
              placeholder="Enter your email"
              dataTestId={className}
              isFloating={true}
              v2
              // error={checkoutErrors['email']}
            />
            {showPhoneField && (
              <div className="flex flex-row items-end">
                <div className="flex-shrink px-2">
                  <FormField
                    fieldType="select"
                    name="dialCodeId"
                    label="Phone Number"
                    onChange={(e) => {
                      onDialCodeIdChange(e.target.value);
                    }}
                    value={dialCodeId}
                    dataTestId={className}
                    optionsList={formatteddialCodeOptions}
                    v2
                    isFloating={true}
                    error={checkoutErrors['dialCodeId']}
                  />
                </div>
                <div className="flex-1 px-2">
                  <FormField
                    fieldType="input"
                    name="phone"
                    onChange={(e) => {
                      onPhoneNumberChange(e.target.value);
                    }}
                    value={phoneNumber}
                    label="Phone Number"
                    placeholder="Enter your phone number"
                    dataTestId={className}
                    v2
                    isFloating={true}
                    error={checkoutErrors['phone']}
                  />
                </div>
              </div>
            )}

            {/* {show1on1Card && <OneOnOneCard />} */}

            <div className={style[`${className}__btn__wrapper`]}>
              <Button
                data-testid="c-PreCheckoutWithEmailForm-button-submit"
                type="submit"
                displayType="form-primary"
                fluid
                rounded={true}>
                {checkoutWithEmailCtaLabel}
              </Button>
            </div>
          </form>
        </>
      )}
      {showSignInOffer && (
        <div
          className={style[`${className}__label`]}
          data-testid="have-account">
          Already have an account?{' '}
          <span
            data-testid="login"
            role="button"
            tabIndex={0}
            className={style[`${className}__label__login`]}
            onClick={selectCheckoutAsLoggedInUser}>
            Log In
          </span>
        </div>
      )}
    </div>
  );
};

PreCheckoutWithEmailForm.defaultProps = {
  isSigningUpWithEmail: false,
  signupEmail: '',
  dialCodeId: '',
  phoneNumber: '',
  loadingText: '',
  checkoutErrors: {},
  checkoutWithEmailCtaLabel: 'Continue With Email'
};

PreCheckoutWithEmailForm.propTypes = {
  selectCheckoutAsLoggedInUser: PropTypes.func,
  checkoutWithEmail: PropTypes.func,
  onSignupEmailChange: PropTypes.func,
  onDialCodeIdChange: PropTypes.func,
  onPhoneNumberChange: PropTypes.func,
  signupWithEmailErr: PropTypes.string,
  signupEmail: PropTypes.string,
  isSigningUpWithEmail: PropTypes.bool,
  show1on1Card: PropTypes.bool,
  dialCodeId: PropTypes.string,
  phoneNumber: PropTypes.string,
  loadingText: PropTypes.string,
  checkoutWithEmailCtaLabel: PropTypes.string
};

export default PreCheckoutWithEmailForm;
