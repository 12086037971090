import { useEffect, useState } from 'react';

import { getCommunitiesList } from '@/services/communitiesService';
import { roles } from '@/utility/constants';
import { useAuthContext } from '@/contexts/AuthContext';

export function getUserRoleInCommunity(communityCode, userCommunities) {
  let isCommunityMember = false;
  let isCommunityAdmin = false;

  if (!Array.isArray(userCommunities))
    return {
      isCommunityMember,
      isCommunityAdmin
    };

  const communityWithGivenCode = userCommunities?.find(
    (userCommunity) => userCommunity?.code === communityCode
  );

  if (!communityWithGivenCode)
    return { isCommunityMember, isCommunityAdmin };

  isCommunityMember = true;
  const currUserRoles =
    communityWithGivenCode?.membersWithRoles?.[0]?.role || [];

  isCommunityAdmin = currUserRoles.includes(roles.manager);

  return { isCommunityMember, isCommunityAdmin };
}

const useUserCommunities = () => {
  const [loading, setLoading] = useState(true);
  const [userCommunities, setUserCommunities] = useState([]);
  const { isLoggedIn } = useAuthContext();

  useEffect(() => {
    const fetchUserCommunities = async () => {
      const { data, error } = await getCommunitiesList();
      if (error) {
        setLoading(false);
        return;
      }

      if (Array.isArray(data?.communities))
        setUserCommunities(data?.communities);
      setLoading(false);
    };

    fetchUserCommunities();
  }, [isLoggedIn]);

  return { loading, userCommunities };
};

export default useUserCommunities;
