import { domainRegions } from './domainRegion';
const SPECIAL_DISCOVERY_CARDS = ['bundle', 'freeworkshop'];

export const getCourseCodesFromAlgoliaSearch = (searchResult) => {
  if (!Array.isArray(searchResult?.hits)) return [];

  const result = searchResult.hits
    .map((algoliaCourseData) => {
      const { customUiData, mode } = algoliaCourseData || {};
      if (!customUiData) return null;

      const { showPrice, showRatings, courseCode, isWaitlist } =
        customUiData;

      return ((showPrice || showRatings) && courseCode && !isWaitlist) ||
        SPECIAL_DISCOVERY_CARDS.includes(mode)
        ? courseCode
        : null;
    })
    .filter((x) => !!x);

  return result;
};

export const getDefaultRegionFilters = ({ region }) => {
  switch (region) {
    case domainRegions.PHILIPPINES:
      return [];
    default:
      return [['domainAccess:International', 'domainAccess:bundleAccess']]; // .com case: International and degrees.
  }
};

const philippinesCoursesOnTop = (coursesList) => {
  const philippinesCourses = coursesList.hits.filter((course) =>
    course.domainAccess.includes('Philippines')
  );

  const otherCourses = coursesList.hits.filter(
    (course) => !course.domainAccess.includes('Philippines')
  );

  return {
    ...coursesList,
    hits: [...philippinesCourses, ...otherCourses]
  };
};

export const getDefaultRegionSorting = ({ data, region }) => {
  switch (region) {
    case domainRegions.PHILIPPINES:
      return philippinesCoursesOnTop(data);
    default:
      return data;
  }
};

export const formatCategories = (serverCategoriesResponse) => {
  // Error case if serverCategoriesResponse is not an array
  if (!Array.isArray(serverCategoriesResponse)) return [];

  // Convert iconImg string from server to NextImage object
  return serverCategoriesResponse.map((serverCategroy) => {
    const { title, iconImg } = serverCategroy;
    return {
      ...serverCategroy,
      iconImg: {
        alt: title,
        mobileImgProps: {
          src: iconImg,
          layout: 'fill'
        }
      }
    };
  });
};
