/* eslint-disable jsx-a11y/click-events-have-key-events */
import { PropTypes } from 'prop-types';
import React from 'react';

import LoginForm from '../../LoginModal/partials/LoginForm';
import style from './PreCheckoutAsLoggedInUserForm.module.scss';

const PreCheckoutAsLoggedInUserForm = ({
  formRef,
  onLoginSubmit,
  onLoginChange,
  loginError,
  deselectCheckoutAsLoggedInUser,
  loginLoading,
  show1on1Card,
  loginInputErrors,
  showPhoneField,
  showSignUpOffer = true,
  signupEmail
}) => {
  const className = 'c-PreCheckoutAsLoggedInUserForm';

  return (
    <div className={style[className]}>
      {/*Precheckout form here*/}
      <LoginForm
        onSubmit={onLoginSubmit}
        onChange={onLoginChange}
        formRef={formRef}
        error={loginError}
        isLoading={loginLoading}
        show1on1Card={show1on1Card}
        loginInputErrors={loginInputErrors}
        isSignUpForm={true}
        showPhoneField={showPhoneField}
        defaultEmail={signupEmail}
      />
      {showSignUpOffer && (
        <div className={style[`${className}__signup-with-email`]}>
          Need an account?{' '}
          <span
            role="button"
            tabIndex={0}
            onClick={deselectCheckoutAsLoggedInUser}>
            Sign up
          </span>
        </div>
      )}
    </div>
  );
};

PreCheckoutAsLoggedInUserForm.defaultProps = {
  loginLoading: false
};

PreCheckoutAsLoggedInUserForm.propTypes = {
  formRef: PropTypes.object,
  onLoginSubmit: PropTypes.func,
  onLoginChange: PropTypes.func,
  loginError: PropTypes.string,
  deselectCheckoutAsLoggedInUser: PropTypes.func,
  loginLoading: PropTypes.bool,
  show1on1Card: PropTypes.bool,
  loginInputErrors: PropTypes.object
};

export default PreCheckoutAsLoggedInUserForm;
