/* eslint-disable react-hooks/exhaustive-deps */
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useRouter } from 'next/router';
import algoliasearch from 'algoliasearch/lite';
import searchInsights from 'search-insights';
import config from '@/utility/config';
import { trackSearchClick } from '@/utility/analytics';
import { useUserSessionContext } from '@/contexts/UserSessionContext';
import { getDefaultRegionFilters } from '@/utility/algoliaHelpers';
import { getDomainRegion } from '@/utility/domainRegion';

const { algoliaAppId, algoliaApiKey, algoliaSearchIndex } = config;
const DEFAULT_PLACEHOLDER = 'Search for Creators, Classes & Others';
//TODO to change default search query to reflect featured courses output
const DEFAULT_SEARCH_TITLE = 'Courses';
export const DEFAULT_INDEX_NAME = algoliaSearchIndex;
export const SEARCH_HIT_KEY = 'searchHit';

const useSearchBox = ({ indexName }) => {
  const [isOnSearch, setIsOnSearch] = useState(false);
  const [placeHolder, setPlaceHolder] = useState(DEFAULT_PLACEHOLDER);
  const [defaultHits, setDefaultHits] = useState(null);
  const [showReset, setShowReset] = useState(false);
  const [searchTitle] = useState(DEFAULT_SEARCH_TITLE);
  const [queryValue, setQueryValue] = useState(null);
  const [featuredHits, setFeaturedHits] = useState([]);
  const [showFeaturedHits, setShowFeaturedHits] = useState(false);

  const { sessionSearchClient, setSessionSearchClient } =
    useUserSessionContext();

  // Get search client from user session
  // Else init a new client
  const searchClient = useMemo(() => {
    if (!sessionSearchClient) {
      const newClient = algoliasearch(algoliaAppId, algoliaApiKey);
      setSessionSearchClient(newClient);
      return newClient;
    } else {
      return sessionSearchClient;
    }
  }, [sessionSearchClient]);

  const searchIndex = useMemo(() => searchClient.initIndex(indexName), []);

  const regionFacetFilter = useMemo(
    () =>
      getDefaultRegionFilters({
        region: getDomainRegion()
      }),
    []
  );

  const router = useRouter();

  const sendSearchAnalyticEvent = (
    hit,
    methodName,
    eventName,
    gtmEventName
  ) => {
    const isClickEvent = eventName === 'courseCardClick';
    const eventObj = isClickEvent
      ? {
          userToken: 'general',
          eventName: eventName,
          index: DEFAULT_INDEX_NAME,
          queryID: hit?.__queryID,
          objectIDs: [hit?.objectID],
          positions: [hit?.__position]
        }
      : {
          userToken: 'general',
          eventName: eventName,
          index: DEFAULT_INDEX_NAME,
          queryID: hit?.__queryID,
          objectIDs: [hit?.objectID]
        };
    searchInsights(methodName, eventObj);

    // Send gtm event
    trackSearchClick({
      eventName: gtmEventName,
      algoliaIndexName: DEFAULT_INDEX_NAME,
      algoliaDisplayedObjectIDs: hit?.objectID,
      userToken: 'general',
      courseCode: hit?.code
    });

    // store hit in session for conversion tracking after click through
    if (isClickEvent) {
      window.sessionStorage.setItem(SEARCH_HIT_KEY, JSON.stringify(hit));
    }
  };

  const sendAfterSearchClickEvent = (hit) => {
    sendSearchAnalyticEvent(
      hit,
      'clickedObjectIDsAfterSearch',
      'courseCardClick',
      'Item Clicked'
    );
  };

  const sendAfterSearchConversionEvent = (
    hit,
    eventName,
    gtmEventName
  ) => {
    sendSearchAnalyticEvent(
      hit,
      'convertedObjectIDsAfterSearch',
      eventName,
      gtmEventName
    );
  };

  const sendConversionEvent = (eventName, gtmEventName) => {
    const searchHit = window.sessionStorage.getItem(SEARCH_HIT_KEY);
    if (searchHit) {
      const parsedSearchHit = JSON.parse(searchHit);
      sendAfterSearchConversionEvent(
        parsedSearchHit,
        eventName,
        gtmEventName
      );
    }
  };

  const getResetElm = useCallback(
    () => document.getElementsByClassName('ais-SearchBox-reset')[0],
    []
  );
  const getSearchInputElm = useCallback(
    () => document.getElementsByClassName('ais-SearchBox-input')[0]
  );

  const getDropdownElm = useCallback(
    () => document.getElementsByClassName('c-Search-Dropdown')[0]
  );

  const onChange = (event) => {
    //Reset default his and placeholder
    setDefaultHits(null);
    setPlaceHolder(DEFAULT_PLACEHOLDER);
    setShowFeaturedHits(false);

    if (!isOnSearch) {
      resetSearchBoxWidth();
    }

    // Check if input is empty
    // Reset expanded state
    // Remove reset icon
    // Otherwise show reset icon

    const input = event.target.value;
    if (input === '' || !input) {
      setShowReset(false);
      setQueryValue(null);
    } else {
      setShowReset(true);
      setQueryValue(input.trimLeft());
    }
  };

  const onReset = () => {
    setShowReset(false);
    setQueryValue(null);
    setPlaceHolder(DEFAULT_PLACEHOLDER);
    const searchInput = getSearchInputElm();
    searchInput?.blur();
    if (isOnSearch) {
      resetSearchBoxWidth();
      setIsOnSearch(false);
    }
    if (defaultHits) {
      setDefaultHits(null);
      setShowReset(false);
    }
  };

  const closeSearch = () => {
    onReset();
    const resetEl = getResetElm();
    resetEl?.click();
    setIsOnSearch(false);
  };

  const resetSearchBoxWidth = () => setIsOnSearch((prev) => !prev);

  const triggerAutoSearch = (query) => {
    resetSearch(query);
    showSearchDropdown();
  };

  const resetSearch = (query) => {
    searchIndex
      .search(query, {
        highlightPreTag: '<ais-highlight-0000000000>',
        highlightPostTag: '</ais-highlight-0000000000>',
        ruleContexts: 'mobile',
        clickAnalytics: true,
        facets: [['domainAccess:Philippines', 'domainAccess:bundleAccess']]
      })
      .then((res) => {
        setDefaultHits(res?.hits);
      });
  };

  const refetchLastSearch = () => {
    resetSearch(queryValue);
  };

  const showSearchDropdown = () => {
    setShowReset(true);
    setIsOnSearch(true);
  };

  // Limit input length to 30 characters
  useEffect(() => {
    const inputDiv = getSearchInputElm();
    if (inputDiv) {
      inputDiv.setAttribute('maxlength', 30);

      const searchBox =
        document.getElementsByClassName('ais-SearchBox')[0];

      const focusInput = (e) => {
        e.stopPropagation();
        inputDiv.focus();
        inputDiv.select();
        showSearchDropdown();
      };

      if (searchBox) {
        searchBox.addEventListener('click', focusInput);
      }
    }
  }, []);

  // If there is query params ?q=
  // Trigger search event
  useEffect(() => {
    const query = router.query?.q;
    setPlaceHolder(query);
    setQueryValue(query);

    if (query) {
      triggerAutoSearch(query);
    } else {
      onReset();
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [router]);

  //When search input is focus and no current input value
  //Show featured courses
  useEffect(() => {
    const inputEl = document.getElementsByClassName(
      'ais-SearchBox-input'
    )[0];
    if (!queryValue) {
      inputEl?.addEventListener(
        'focus',
        () => {
          //Check if this can be configured from backend for auto featured courses
          // triggerAutoSearch(DEFAULT_SEARCH_QUERY);
          // setSearchTitle(FEATURED_SEARCH_TITLE);
          showSearchDropdown();
        },
        { once: true }
      );
      inputEl?.addEventListener(
        'click',
        () => {
          //Check if this can be configured from backend for auto featured courses
          // triggerAutoSearch(DEFAULT_SEARCH_QUERY);
          // setSearchTitle(FEATURED_SEARCH_TITLE);
          showSearchDropdown();
        },
        { once: true }
      );
    }
  }, [queryValue, triggerAutoSearch]);

  useEffect(() => {
    const resetEl = getResetElm();
    if (resetEl) {
      if (showReset) {
        resetEl.style.display = 'block';
      } else {
        resetEl.style.display = 'none';
      }
    }
  }, [showReset]);

  // Edge case: reset when clicking on escape for autofocus
  // Clear search value and dropdown
  useEffect(() => {
    const escapeHandler = (e) => {
      if (e.key === 'Escape' && isOnSearch) {
        const resetEl = getResetElm();
        if (resetEl) {
          resetEl?.click();
          window.removeEventListener('keydown', escapeHandler);
        }
      }
    };
    window.addEventListener('keydown', escapeHandler);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isOnSearch]);

  useEffect(() => {
    if (!isOnSearch) {
      const inputEl = getSearchInputElm();

      inputEl?.setAttribute('readonly', true);
      const timeout = setTimeout(() => {
        inputEl?.removeAttribute('readonly');
        clearTimeout(timeout);
      }, 300);
    } else {
      const resetEl = getResetElm();
      resetEl?.removeAttribute('hidden');
    }
  }, [isOnSearch]);

  useEffect(() => {
    const searchInputElm = getSearchInputElm();
    const focusInput = () => {
      searchInputElm?.focus();
      document.activeElement.focus();
    };
    const showKeyboard = () => {
      searchInputElm?.addEventListener('click', focusInput);
      searchInputElm?.addEventListener('touchstart', focusInput);
      searchInputElm?.addEventListener('touchmove', (e) =>
        e.preventDefault()
      );
    };

    showKeyboard();
  }, []);

  useEffect(() => {
    const dropdownElm = getDropdownElm();
    const searchInputElm = getSearchInputElm();

    const setSearchInputAsReadonly = () => {
      // Disable keyboard if user scroll up to view more
      searchInputElm?.blur();
      document.activeElement.blur();
    };

    if (isOnSearch) {
      dropdownElm?.addEventListener('scroll', setSearchInputAsReadonly, {
        once: true
      });
      searchInputElm.focus();
    }
  }, [isOnSearch]);

  useEffect(() => {
    if (algoliaApiKey && algoliaAppId) {
      searchInsights('init', {
        appId: algoliaAppId,
        apiKey: algoliaApiKey
      });
    }
  }, []);

  return {
    isOnSearch,
    setIsOnSearch,
    onChange,
    onReset,
    searchClient,
    defaultHits,
    placeHolder,
    searchTitle,
    queryValue,
    closeSearch,
    showFeaturedHits,
    setShowFeaturedHits,
    setFeaturedHits,
    featuredHits,
    refetchLastSearch,
    sendAfterSearchClickEvent,
    sendAfterSearchConversionEvent,
    sendConversionEvent,
    regionFacetFilter
  };
};

export default useSearchBox;
