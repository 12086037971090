import useSearchBox, { DEFAULT_INDEX_NAME } from '@/hooks/useSearchBox';
import {
  signUpCheck,
  submitFreeCommunityEnrollment
} from '@/services/communitiesService';
import { useEffect, useState } from 'react';
import {
  trackAddCourseToCart,
  trackGAEvent,
  trackInitiateCheckout
} from '../../utility/analytics';
import {
  CHECKOUT_TYPE_BUNDLE,
  CHECKOUT_TYPE_COMMUNITY,
  CHECKOUT_TYPE_COURSE,
  DEFAULT_CURRENCY
} from '../../utility/checkoutConstants';

import {
  showErrorToast,
  showToast
} from '@/components/common/ToastContainer';
import { DISCORD_UUID_KEY } from '@/features/community/constants';
import { getLocalPriceInfo } from '@/services/paymentService';
import { getTimezoneId } from '@/utility/dateHelper';
import { isValidPhoneFormat } from '@/utility/validation';
import { useAuthContext } from '../../contexts/AuthContext';
import config from '../../utility/config';
import useStudentLogin from '../useStudentLogin';
import useInitCheckoutWithEmail from './useInitCheckoutWithEmail';

const { recapchaSiteToken } = config;

const LOADING_TEXT_WAIT_FOR_COURSE_DATA =
  'Waiting for the Course Data to load. Please be patient';
const useStartCheckout = ({
  courseData,
  courseCode,
  groupCode,
  communityCode,
  autoCreateBundle,
  isFreeWorkshop,
  slug,
  // Community data
  isCommunity,
  isFreeCommunity,
  shouldPreventCheckoutRedirect,
  postSignupCallback,
  onUserAlreadyExists,
  actionType = '',
  sourceInfo,
  navigateToCommunity,
  requestor,
  discountCodeFromQueryParams,
  entityDiscountCodeFromQueryParams
}) => {
  const { user, userEnrollmentData } = useAuthContext();
  const loggedInLearner = user?.learner;
  const [showPreCheckoutModal, setShowPreCheckoutModal] = useState(false);
  const [showFreeWorkshopUpsellModal, setShowFreeWorkshopUpsellModal] =
    useState(false);

  const [checkoutType, setCheckoutType] = useState('');
  const [selectedCourseOfferingId, setSelectedCourseOfferingId] =
    useState(null);

  // Community Checkout state variables
  const [selectedCommunityCode, setSelectedCommunityCode] = useState(null);
  const [interval, setInterval] = useState(1);
  const [intervalCount, setIntervalCount] = useState('month');
  const [priceId, setPriceId] = useState('month');

  const [bundleData, setBundleData] = useState(null);
  const [defaultExchangeRate, setDefaultExchangeRate] = useState(1);
  const [isCommunityEnrollmentComplete, setIsCommunityEnrollmentComplete] =
    useState(false);

  const {
    signupEmail,
    setSignupEmail,
    dialCodeId,
    setDialCodeId,
    phoneNumber,
    setPhoneNumber,
    signupWithEmailErr,
    setSignupWithEmailErr,
    isSigningUpWithEmail,
    setIsSigningUpWithEmail,
    loadingText,
    setLoadingText,
    isUserAlreadyEnrolled,
    setIsUserAlreadyEnrolled,
    signupWithEmailSuccess,
    setSignupWithEmailSuccess,
    redirectLink,
    initCheckoutWithEmail,
    loadingRedirectLink,
    signUpErrors,
    setSignUpErrors,
    showFreeSignupSuccessModal,
    closeFreeSignupSuccessModal,

    //
    shouldStartOutsideRedirectFlow,
    setShouldStartOutsideRedirectFlow
  } = useInitCheckoutWithEmail({
    isFreeWorkshop,
    isCommunity,
    isFreeCommunity,
    shouldPreventCheckoutRedirect,
    actionType,
    sourceInfo,
    requestor,
    discountCodeFromQueryParams,
    entityDiscountCodeFromQueryParams
  });

  //Search event
  const { sendConversionEvent } = useSearchBox({
    indexName: DEFAULT_INDEX_NAME
  });

  const [promptError, setPromptError] = useState('');

  const {
    formRef,
    onLoginSubmit,
    onLoginChange,
    loginError,
    loginLoading,
    loginInputErrors
  } = useStudentLogin();

  const resetPromptError = () => {
    setPromptError('');
    setSignupWithEmailErr('');
    setSignUpErrors({});
    setSignupEmail('');
    setDialCodeId('');
    setPhoneNumber('');
  };

  const initLoadingScreen = () => {
    // Show only for 2s
    setTimeout(() => {
      setShowPreCheckoutModal(false);
      setIsSigningUpWithEmail(false);
      setLoadingText('');
    }, 2000);
    setShowPreCheckoutModal(true);
    setIsSigningUpWithEmail(true);
    setLoadingText(LOADING_TEXT_WAIT_FOR_COURSE_DATA);
  };

  // For course purchase Join Click
  const onJoinClick = async (courseOfferingId) => {
    if (!courseOfferingId) {
      initLoadingScreen();
    } else {
      setIsSigningUpWithEmail(false);
      // Show Pre Checkout Modal
      setSelectedCourseOfferingId(courseOfferingId);
      setCheckoutType(CHECKOUT_TYPE_COURSE);

      const email = loggedInLearner?.email;
      if (email) {
        await initCheckoutWithEmail({
          checkoutType: CHECKOUT_TYPE_COURSE,
          courseCode,
          groupCode,
          email,
          courseOfferingId,
          phoneNumber,
          dialCodeId
        });
        if (signupWithEmailSuccess) {
          // In case of success close this modal
          // redirect link will be set in the initCheckoutWithEmail
          setShowPreCheckoutModal(false);
          if (isFreeWorkshop) {
            setShowFreeWorkshopUpsellModal(true);
          }
        }
      } else {
        setShowPreCheckoutModal(true);
      }

      // Track Analytics
      if (!courseData) return;
      const { price } = courseData;
      trackAddCourseToCart({
        courseCode: courseCode ? courseCode : communityCode,
        courseOfferingId,
        currencyCode: 'USD',
        price: price * defaultExchangeRate
      });

      //Send algolia add to cart conversion event
      sendConversionEvent('addToCart', 'Item Converted To Cart');
    }
  };

  const onCommunityCheckoutClick = async ({
    communityCode,
    interval = 'month',
    intervalCount = 1,
    inputEmail,
    inputPhoneNumber,
    inputFullName,
    requestor = '',
    priceId
  }) => {
    setSelectedCommunityCode(communityCode);
    setInterval(interval);
    setPriceId(priceId);
    setIntervalCount(intervalCount);
    setCheckoutType(CHECKOUT_TYPE_COMMUNITY);

    const email = inputEmail ?? loggedInLearner?.email;
    if (email) {
      console.log('pre call');
      const error = await initCheckoutWithEmail({
        checkoutType: CHECKOUT_TYPE_COMMUNITY,
        slug,
        courseCode,
        groupCode,
        email,
        courseOfferingId: communityCode,
        phoneNumber: inputPhoneNumber ?? phoneNumber,
        fullName: inputFullName,
        dialCodeId,
        interval,
        intervalCount,
        requestor,
        priceId
      });

      if (signupWithEmailSuccess) {
        // In case of success close this modal
        // redirect link will be set in the initCheckoutWithEmail
        setShowPreCheckoutModal(false);
      }
      if (isFreeCommunity && shouldPreventCheckoutRedirect) {
        // This is the case if there's a free Community.. We want to enroll them
        // handle enrollment here since this is the best place
        const discordUUID = sessionStorage.getItem(DISCORD_UUID_KEY);
        let payload = {
          communityCode: selectedCommunityCode,
          timezone: getTimezoneId(),
          email
        };
        if (discordUUID) {
          payload = {
            ...payload,
            uuid: discordUUID
          };
        }
        const { error: freeCommunityError } =
          await submitFreeCommunityEnrollment(payload);
        if (freeCommunityError) {
          if (!/unexpected non-whitespace/gi.test(freeCommunityError)) {
            // throw new Error(freeCommunityError);
            showErrorToast(freeCommunityError);
            return;
          }
        }

        if (postSignupCallback) {
          await postSignupCallback(freeCommunityError);
          setShouldStartOutsideRedirectFlow(false);
        }
        setIsCommunityEnrollmentComplete(true);
      }

      if (error) {
        return error;
      }

      return signupWithEmailSuccess;
    } else {
      setShowPreCheckoutModal(true);
    }

    // analytics
    let initCheckoutPayload = {
      communityCode,
      email
    };

    if (sourceInfo) {
      initCheckoutPayload = {
        ...initCheckoutPayload,
        sourceInfoType: sourceInfo?.type,
        sourceInfoOrigin: sourceInfo?.origin
      };
    }
    trackInitiateCheckout(initCheckoutPayload);
  };

  const onCheckoutBundleClick = async ({
    bundleId,
    bundleSlug,
    bundleCourses,
    isGiftPurchase
  }) => {
    // Show Pre Checkout Modal
    const bundleDataParam = {
      bundleId,
      bundleSlug,
      bundleCourses,
      isGiftPurchase
    };
    setBundleData(bundleDataParam);
    setCheckoutType(CHECKOUT_TYPE_BUNDLE);

    const email = loggedInLearner?.email;
    if (email) {
      // if user is logged in. Initiate checkout with logged in user email.
      await initCheckoutWithEmail({
        checkoutType: CHECKOUT_TYPE_BUNDLE,
        email,
        phoneNumber,
        dialCodeId,
        bundleData: bundleDataParam,
        bundleCourses,
        autoCreateBundle
      });
    } else {
      setShowPreCheckoutModal(true);
    }
  };

  const checkoutWithEmail = async (
    email,
    phoneNumber,
    dialCodeId,
    opts = {
      isRegister: false
    }
  ) => {
    if (opts.isRegister) {
      const hasAccount = await signUpCheck(email);
      if (hasAccount?.data?.userExists) {
        if (onUserAlreadyExists) {
          setShowPreCheckoutModal(false);
          setSignupEmail(email);
          onUserAlreadyExists();
          return;
        }
      }
    }
    let checkoutWithEmailParams = {
      courseCode,
      groupCode,
      checkoutType,
      email,
      autoCreateBundle,
      ...{
        phoneNumber: isValidPhoneFormat(phoneNumber) ? phoneNumber : null
      },
      dialCodeId
    };

    if (
      !selectedCourseOfferingId &&
      checkoutType === CHECKOUT_TYPE_COURSE
    ) {
      initLoadingScreen();
    } else {
      if (checkoutType === CHECKOUT_TYPE_BUNDLE) {
        checkoutWithEmailParams.bundleData = bundleData;
        checkoutWithEmailParams.bundleCourses = bundleData?.bundleCourses;
      } else if (checkoutType === CHECKOUT_TYPE_COURSE) {
        checkoutWithEmailParams.courseOfferingId =
          selectedCourseOfferingId;
      } else if (checkoutType === CHECKOUT_TYPE_COMMUNITY) {
        // Course offering is community code
        checkoutWithEmailParams.communityCode = selectedCommunityCode;
        checkoutWithEmailParams.interval = interval;
        checkoutWithEmailParams.intervalCount = intervalCount;
        checkoutWithEmailParams.slug = slug;
        checkoutWithEmailParams.withRedirect = !(
          isFreeCommunity && shouldPreventCheckoutRedirect
        );
        checkoutWithEmailParams.priceId = priceId;
      }

      const initCheckoutData = await initCheckoutWithEmail(
        checkoutWithEmailParams
      );

      if (initCheckoutData?.error) return;

      const bookingId = initCheckoutData?.id;

      // In case of free Community we want to give back control to the parent component
      if (isFreeCommunity && shouldPreventCheckoutRedirect) {
        // This is the case if there's a free Community.. We want to enroll them
        // handle enrollment here since this is the best place

        const discordUUID = sessionStorage.getItem(DISCORD_UUID_KEY);
        let payload = {
          communityCode: selectedCommunityCode,
          timezone: getTimezoneId(),
          email
        };
        if (discordUUID) {
          payload = {
            ...payload,
            uuid: discordUUID
          };
        }
        const { error: freeCommunityError, data } =
          await submitFreeCommunityEnrollment(payload);
        if (freeCommunityError) {
          if (!/unexpected non-whitespace/gi.test(freeCommunityError)) {
            throw new Error(freeCommunityError);
          }
        }

        if (postSignupCallback) {
          await postSignupCallback({
            bookingId
          });
          setShouldStartOutsideRedirectFlow(false);
        }

        setIsCommunityEnrollmentComplete(true);
        setShowPreCheckoutModal(false);

        const gaTrackingPayload = {
          email,
          bookingId,
          communityCode,
          sourceInfoType: sourceInfo?.type,
          sourceInfoOrigin: sourceInfo?.origin
        };
        trackGAEvent('signup_success', gaTrackingPayload);
      }

      if (postSignupCallback) {
        try {
          await postSignupCallback({
            bookingId
          });
        } catch (err) {
          console.error('Error in postSignupCallback', err);
          showToast({ text: 'Something went wrong', type: 'error' });
        }
      }
      setIsCommunityEnrollmentComplete(true);

      setShowPreCheckoutModal(false);
      return;
    }
  };

  const handleCheckoutWithEmail = (e) => {
    e.preventDefault();

    const trimmedEmail = signupEmail?.trim();
    if (!trimmedEmail) {
      showErrorToast('Email is required.');
      return;
    }

    checkoutWithEmail(trimmedEmail, phoneNumber, dialCodeId, {
      isRegister: true
    });
  };

  const onSignupEmailChange = (value) => {
    setSignupEmail(value);
  };

  const onDialCodeIdChange = (value) => {
    setDialCodeId(value);
  };

  const onPhoneNumberChange = (value) => {
    setPhoneNumber(value);
  };

  const getDefaultExchangeRate = async (currency) => {
    const setDefaultRate = (rate) => {
      window.sessionStorage.setItem('exchangeRateToUsd', rate.toString());
      setDefaultExchangeRate(rate);
    };
    // Call local price api to get exchange rate if course currency is not USD
    if (currency !== DEFAULT_CURRENCY) {
      const exchangeRateRes = await getLocalPriceInfo(true);
      if (exchangeRateRes?.data) {
        const defaultRate = exchangeRateRes?.data?.conversionMultiplier;
        setDefaultRate(defaultRate);
      } else {
        setDefaultRate(1);
      }
    } else {
      setDefaultRate(1);
    }
  };

  // If pre checkout modal is showing and user logs in,
  // run checkoutWithEmail function to initiate checkout with logged in user.
  useEffect(() => {
    const email = user?.learner?.email;
    const isUsrAlreadyEnrolled =
      userEnrollmentData?.[courseCode ? courseCode : communityCode]
        ?.isEnrolled ?? isUserAlreadyEnrolled;

    // if user is already enrolled close the modal
    if (isUsrAlreadyEnrolled) {
      setShowPreCheckoutModal(false);
      return;
    }

    if (email && showPreCheckoutModal) {
      checkoutWithEmail(email, null, null);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedCourseOfferingId, userEnrollmentData]);

  // If error occurred during signup, close the modal and show prompt with error
  useEffect(() => {
    const loggedInLearner = user?.learner;
    const error = signupWithEmailErr || '';
    if (loggedInLearner && error) {
      // Redirect to community if already enrolled
      if (isUserAlreadyEnrolled) {
        if (navigateToCommunity) {
          navigateToCommunity();
          return;
        }
      }

      setShowPreCheckoutModal(false);
      // setPromptError(`${error}. Please choose another course.`);
    }
  }, [signupWithEmailErr, user, isUserAlreadyEnrolled]);

  //If the signup is successful close the modal
  useEffect(() => {
    if (signupWithEmailSuccess) {
      setShowPreCheckoutModal(false);
      setShowFreeWorkshopUpsellModal(false);
    }
  }, [signupWithEmailSuccess]);

  // Get default exchange rate
  useEffect(() => {
    if (courseData?.currency) {
      const { currency } = courseData;
      getDefaultExchangeRate(currency);
    }
  }, [courseData]);

  return {
    // modal status
    showPreCheckoutModal,
    setShowPreCheckoutModal,

    // cta
    onJoinClick, // for course checkout
    onCheckoutBundleClick, // for bundle checkout
    onCommunityCheckoutClick, // for community checkout

    // Login Form
    formRef,
    onLoginSubmit,
    onLoginChange,
    loginError,
    loginLoading: loginLoading || loadingRedirectLink,
    loginInputErrors,

    // Signup w/ Email Form
    onSignupEmailChange,
    onDialCodeIdChange,
    onPhoneNumberChange,
    handleCheckoutWithEmail,
    signupWithEmailErr,
    signUpErrors,
    setSignUpErrors,
    signupEmail,
    dialCodeId,
    phoneNumber,
    isSigningUpWithEmail,
    loadingText,
    isUserAlreadyEnrolled,
    setIsUserAlreadyEnrolled,
    signupWithEmailSuccess,
    setSignupWithEmailSuccess,
    redirectLink,
    setIsSigningUpWithEmail,
    showFreeSignupSuccessModal,
    closeFreeSignupSuccessModal,

    // error prompt data
    promptError,
    resetPromptError,
    sendConversionEvent,

    // free community
    shouldStartOutsideRedirectFlow,
    setShouldStartOutsideRedirectFlow,
    isCommunityEnrollmentComplete
  };
};

export default useStartCheckout;
